export const home = {
  title: "Warehouse-Home",
  description: "",
};
export const login = {
  title: "Login -  ",
  description: "",
};
export const register = {
  title: "Register -  ",
  description: "",
};
export const payment = {
  title: "Payment -  ",
  description: "",
};
export const profile = {
  title: "Profile -  ",
  description: "",
};
