<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.75 8.25H14.9475C14.6032 5.53486 12.4651 3.39681 9.75 3.0525V2.25C9.75 1.83579 9.41421 1.5 9 1.5C8.58579 1.5 8.25 1.83579 8.25 2.25V3.0525C5.53486 3.39681 3.39681 5.53486 3.0525 8.25H2.25C1.83579 8.25 1.5 8.58579 1.5 9C1.5 9.41421 1.83579 9.75 2.25 9.75H3.0525C3.39681 12.4651 5.53486 14.6032 8.25 14.9475V15.75C8.25 16.1642 8.58579 16.5 9 16.5C9.41421 16.5 9.75 16.1642 9.75 15.75V14.9475C12.4651 14.6032 14.6032 12.4651 14.9475 9.75H15.75C16.1642 9.75 16.5 9.41421 16.5 9C16.5 8.58579 16.1642 8.25 15.75 8.25ZM6.75 9C6.75 7.75736 7.75736 6.75 9 6.75C10.2426 6.75 11.25 7.75736 11.25 9C11.25 10.2426 10.2426 11.25 9 11.25C7.75736 11.25 6.75 10.2426 6.75 9ZM8.25 9C8.25 9.41421 8.58579 9.75 9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9ZM9 12.6825C9.41421 12.6825 9.75 13.0183 9.75 13.4325C11.6353 13.1121 13.1121 11.6353 13.4325 9.75C13.0183 9.75 12.6825 9.41421 12.6825 9C12.6825 8.58579 13.0183 8.25 13.4325 8.25C13.1121 6.36475 11.6353 4.88787 9.75 4.5675C9.75 4.98171 9.41421 5.3175 9 5.3175C8.58579 5.3175 8.25 4.98171 8.25 4.5675C6.36475 4.88787 4.88787 6.36475 4.5675 8.25C4.98171 8.25 5.3175 8.58579 5.3175 9C5.3175 9.41421 4.98171 9.75 4.5675 9.75C4.88787 11.6353 6.36475 13.1121 8.25 13.4325C8.25 13.0183 8.58579 12.6825 9 12.6825Z"
      fill="#48BEEA"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
