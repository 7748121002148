<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.333496 7.00016C0.333496 3.31826 3.31826 0.333496 7.00016 0.333496C8.76827 0.333496 10.464 1.03588 11.7142 2.28612C12.9645 3.53636 13.6668 5.23205 13.6668 7.00016C13.6668 10.6821 10.6821 13.6668 7.00016 13.6668C3.31826 13.6668 0.333496 10.6821 0.333496 7.00016ZM1.66683 7.00016C1.66683 9.94568 4.05464 12.3335 7.00016 12.3335C8.41465 12.3335 9.7712 11.7716 10.7714 10.7714C11.7716 9.7712 12.3335 8.41465 12.3335 7.00016C12.3335 4.05464 9.94568 1.66683 7.00016 1.66683C4.05464 1.66683 1.66683 4.05464 1.66683 7.00016ZM10.9468 6.0135C10.867 5.7763 10.661 5.60381 10.4135 5.56683L8.4735 5.26683L7.60016 3.40683C7.49776 3.16489 7.26282 3.00566 7.00016 3.00016C6.7422 3.00211 6.5085 3.15271 6.40016 3.38683L5.52016 5.26683L3.58016 5.56683C3.33168 5.60293 3.12455 5.77558 3.0443 6.01351C2.96405 6.25144 3.0243 6.51427 3.20016 6.6935L4.62683 8.14683L4.28683 10.2135C4.23966 10.4701 4.34647 10.7306 4.56016 10.8802C4.6735 10.9593 4.80862 11.0012 4.94683 11.0002C5.05934 11.0005 5.16988 10.9706 5.26683 10.9135L7.00016 9.94683L8.72683 10.9002C8.94934 11.0253 9.22375 11.0123 9.4335 10.8668C9.64719 10.7172 9.75399 10.4567 9.70683 10.2002L9.3735 8.1335L10.7935 6.68016C10.9634 6.5031 11.0223 6.247 10.9468 6.0135ZM7.9935 8.02683C7.95908 7.81798 8.02588 7.6052 8.1735 7.4535L8.9335 6.68683L7.92683 6.5335C7.70613 6.50162 7.51599 6.36151 7.42016 6.16016L7.00016 5.26016L6.5735 6.16016C6.47895 6.35974 6.29171 6.49955 6.0735 6.5335L5.06016 6.68683L5.78683 7.4535C5.93444 7.6052 6.00125 7.81798 5.96683 8.02683L5.80016 9.07349L6.64683 8.60683C6.8531 8.48774 7.10723 8.48774 7.3135 8.60683L8.16683 9.07349L7.9935 8.02683Z"
      fill="#48BEEA"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
