<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M14.7675 6.83226L5.1 1.93476C4.34653 1.50677 3.42347 1.50677 2.67 1.93476C1.93298 2.43718 1.52101 3.29545 1.59 4.18476V13.9198C1.52416 14.8085 1.93541 15.6653 2.67 16.1698C3.00473 16.3714 3.38924 16.4754 3.78 16.4698C4.24032 16.4619 4.6924 16.3463 5.1 16.1323L14.7675 11.2498C16.2 10.4998 16.41 9.56976 16.41 9.05976C16.41 8.54976 16.2 7.55976 14.7675 6.83226Z"
      fill="#48BEEA"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
