import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import Layout from "@/router/layout/layout.vue";
import PageHeader from "@/components/pageHeader.vue";
import BreedCamp from "@/components/BreedCamp.vue";
import FormSkeleton from "@/components/skeletons/FormSkeleton.vue";
import { store } from "@/state/store";
import i18n from "@/i18n";
import ToggleButton from "vue-js-toggle-button";
import Toast, { TYPE } from "vue-toastification";
import toastFailIcon from "@/assets/icons/toastFailIcon.vue";
import toastSuccessIcon from "@/assets/icons/toastSuccessIcon.vue";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import VueGtag from "vue-gtag";
import "@/index.css";
import { vuetify } from "@/plugins";
import "@/design/app.scss";
import "boxicons/css/boxicons.min.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "vue-toastification/dist/index.css";
import "sweetalert2/dist/sweetalert2.min.css";

const options = {
  position: "top-right",
  toastDefaults: {
    [TYPE.ERROR]: {
      icon: toastFailIcon,
      toastClassName: "toast-error",
    },
    [TYPE.SUCCESS]: {
      icon: toastSuccessIcon,
      toastClassName: "toast-success",
    },
  },
};
Vue.config.productionTip = false;
Vue.component("layout", Layout);
Vue.component("page-header", PageHeader);
Vue.component("breed-camp", BreedCamp);
Vue.component("form-skeleton", FormSkeleton);
Vue.use(ToggleButton);
Vue.use(VueSweetalert2);
Vue.use(Toast, options);
Vue.use(Vuelidate);
Vue.use(
  VueGtag,
  {
    config: {
      id: "G-KM76002030",
      params: {
        send_page_view: false,
      },
    },
    appName: "merchant-portal",
    pageTrackerScreenviewEnabled: true,
  },
  router
);
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
