<template>
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.3268 1.04651C12.9543 0.495349 12.3321 0.165504 11.6668 0.166506H2.3335C1.22893 0.166506 0.333496 1.06194 0.333496 2.16651V8.83317C0.333496 9.93774 1.22893 10.8332 2.3335 10.8332H11.6668C12.7714 10.8332 13.6668 9.93774 13.6668 8.83317V2.16651C13.6677 1.76762 13.5493 1.37758 13.3268 1.04651ZM2.3335 1.49983H11.6668C11.8583 1.50008 12.0404 1.58265 12.1668 1.7265L7.00016 4.7465L1.84016 1.71983C1.9662 1.58018 2.14538 1.50028 2.3335 1.49983ZM11.6668 9.49984C12.035 9.49984 12.3335 9.20136 12.3335 8.83317V3.15983L7.66683 5.89317C7.46427 6.01067 7.23434 6.07275 7.00016 6.07317C6.76659 6.07478 6.5367 6.01501 6.3335 5.89984L1.66683 3.15983V8.83317C1.66683 9.20136 1.96531 9.49984 2.3335 9.49984H11.6668Z"
      fill="#48BEEA"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
