<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.99978 1.5C11.8993 1.5 14.2498 3.8505 14.2498 6.75C14.3548 9.0975 12.4723 12.225 9.60728 16.185C9.46624 16.3798 9.24031 16.4952 8.99978 16.4952C8.75926 16.4952 8.53333 16.3798 8.39228 16.185C5.52728 12.2175 3.64478 9.09 3.74978 6.75C3.74978 3.8505 6.10029 1.5 8.99978 1.5ZM8.99978 14.4825C11.0023 11.6475 12.8548 8.655 12.7498 6.8175V6.795V6.75C12.7498 4.67893 11.0709 3 8.99978 3C6.92872 3 5.24978 4.67893 5.24978 6.75V6.795C5.14478 8.6325 6.99728 11.625 8.99978 14.4825ZM8.99978 4.5C7.75714 4.5 6.74978 5.50736 6.74978 6.75C6.74978 7.99264 7.75714 9 8.99978 9C10.2424 9 11.2498 7.99264 11.2498 6.75C11.2498 6.15326 11.0127 5.58097 10.5908 5.15901C10.1688 4.73705 9.59652 4.5 8.99978 4.5ZM8.24978 6.75C8.24978 7.16421 8.58557 7.5 8.99978 7.5C9.414 7.5 9.74978 7.16421 9.74978 6.75C9.74978 6.33579 9.414 6 8.99978 6C8.58557 6 8.24978 6.33579 8.24978 6.75Z"
      fill="#48BEEA"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
