<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.2267 11.6002L13.0467 3.78017C13.5818 3.28158 13.802 2.53069 13.6211 1.82207C13.4401 1.11344 12.8868 0.560112 12.1781 0.379142C11.4695 0.198173 10.7186 0.418429 10.22 0.953504L2.38003 8.7935C2.32989 8.84505 2.28725 8.9034 2.25337 8.96684L0.400034 12.7068C0.298686 12.9137 0.311289 13.1582 0.433367 13.3535C0.555444 13.5488 0.7697 13.6673 1.00003 13.6668C1.11003 13.6642 1.21768 13.6345 1.31337 13.5802L5.05337 11.7268C5.1168 11.693 5.17515 11.6503 5.2267 11.6002ZM13 13.6668C13.3682 13.6668 13.6667 13.3684 13.6667 13.0002C13.6667 12.632 13.3682 12.3335 13 12.3335H7.00003C6.63184 12.3335 6.33337 12.632 6.33337 13.0002C6.33337 13.3684 6.63184 13.6668 7.00003 13.6668H13ZM9.88003 5.06017L8.94003 4.12017L3.42003 9.66684L2.49337 11.5335L4.33337 10.5802L9.88003 5.06017ZM11.6434 1.666C11.8261 1.666 12.0008 1.74102 12.1267 1.8735C12.2566 2.00209 12.328 2.17838 12.3243 2.36113C12.3205 2.54389 12.2418 2.71707 12.1067 2.84017L10.82 4.12017L9.88003 3.18017L11.16 1.8735C11.2859 1.74102 11.4606 1.666 11.6434 1.666Z"
      fill="#344658"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
