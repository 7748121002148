import * as types from "@/state/types";
import { ICreateState } from "./module.interface";
const state: ICreateState = { isCreateFlow: false };
const getters = {
  [types.GET_IS_CREATE_FLOW]: (state: ICreateState) => {
    return state.isCreateFlow;
  },
};
const mutations = {
  [types.SET_IS_CREATE_FLOW]: (state: ICreateState, status: boolean) => {
    state.isCreateFlow = status;
  },
};
export default { state, getters, mutations };
