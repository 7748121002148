import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import {
  pinIcon,
  locationIcon,
  targetIcon,
  warehouseIcon,
  imageIcon,
  MailIcon,
  StarIcon,
  LineIcon,
  RadioIcon,
  PlayIcon,
  UploadIcon,
  DownloadIcon,
  DownloadIconDark,
  uploadIconDark,
  UploadImage,
  UploadVideo,
  checkIcon,
  errorIcon,
  ClockIcon,
  EditIcon,
  redLineIcon,
  CredentialsIcon,
} from "@/assets/icons";
Vue.use(Vuetify);
const opts = {
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#101820",
        "primary-light": "#9FAAB4",
        "primary-lighter": "#DDE1E5",
        secondary: "#e33065",
        info: "#46beea",
        warning: "#f7ac46",
        success: "#2ad03d",
        danger: "#dd173d",
        white: "#ffffff",
      },
    },
  },
  icons: {
    values: {
      pin: {
        component: pinIcon,
      },
      location: {
        component: locationIcon,
      },
      target: {
        component: targetIcon,
      },
      warehouse: {
        component: warehouseIcon,
      },
      imageIcon: {
        component: imageIcon,
      },
      mail: {
        component: MailIcon,
      },
      star: {
        component: StarIcon,
      },
      line: {
        component: LineIcon,
      },
      redLine: {
        component: redLineIcon,
      },
      radio: {
        component: RadioIcon,
      },
      play: {
        component: PlayIcon,
      },
      upload: {
        component: UploadIcon,
      },
      download: {
        component: DownloadIcon,
      },
      downloadDark: {
        component: DownloadIconDark,
      },
      uploadDark: {
        component: uploadIconDark,
      },
      uploadVideo: {
        component: UploadVideo,
      },
      uploadImage: {
        component: UploadImage,
      },
      check: {
        component: checkIcon,
      },
      error: {
        component: errorIcon,
      },
      clock: {
        component: ClockIcon,
      },
      edit: {
        component: EditIcon,
      },
      credentials: {
        component: CredentialsIcon,
      },
    },
  },
};
export const vuetify = new Vuetify(opts);
