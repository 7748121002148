<template>
  <div>
    <!--Start-Loader-->
    <v-dialog v-model="loading" fullscreen full-width>
      <v-container
        fluid
        fill-height
        style="background-color: rgba(12, 25, 34, 0.7)"
      >
        <v-col
          cols="12"
          class="d-flex align-items-center justify-content-center flex-column"
        >
          <div class="loader text-center">
            <div v-for="n in 5" :key="n" class="loader-item"></div>
          </div>
        </v-col>
      </v-container>
    </v-dialog>
    <!--End-Loader-->

    <v-app-bar color="primary" fixed elevate-on-scroll>
      <div class="d-flex align-center">
        <router-link :to="{ name: 'Home' }">
          <v-img
            alt="WeStore"
            class="shrink mr-2"
            :src="require('@/assets/logo/logo-white.png')"
            :width="$vuetify.breakpoint.smAndDown ? 100 : 150"
            transition="scale-transition"
          />
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-md-and-down">
        <v-btn
          class="px-2"
          :ripple="false"
          color="white"
          text
          :href="navLinks.products.target"
        >
          {{ navLinks.products.title }}
        </v-btn>
        <!--Start-Services-Dropdown-->
        <v-menu
          transition="slide-y-transition"
          open-on-hover
          offset-y
          :close-on-click="true"
          content-class="service-menu"
        >
          <template v-slot:activator="{ on }">
            <v-btn class="px-2" :ripple="false" text color="white" v-on="on">
              Services<v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list class="custom-dropdown">
            <v-list-item v-for="(service, index) in services" :key="index">
              <a class="custom-dropdown-title" :href="service.target">
                <v-list-item-title>
                  {{ service.title }}
                </v-list-item-title>
              </a>
            </v-list-item>
          </v-list>
        </v-menu>
        <!--End-Services-Dropdown-->
        <v-btn
          class="px-2"
          color="white"
          :ripple="false"
          :href="navLinks.technology.target"
          text
        >
          {{ navLinks.technology.title }}
        </v-btn>
        <v-btn
          class="px-2"
          color="white"
          :href="navLinks.integrations.target"
          text
        >
          {{ navLinks.integrations.title }}
        </v-btn>
        <v-btn
          class="px-2"
          :ripple="false"
          text
          color="white"
          :href="navLinks.clients.target"
        >
          {{ navLinks.clients.title }}
        </v-btn>
        <!--Start-Company-Dropdown-->
        <v-menu
          transition="slide-y-transition"
          open-on-hover
          offset-y
          :close-on-click="true"
          content-class="company-menu"
        >
          <template v-slot:activator="{ on }">
            <v-btn class="px-2" :ripple="false" text color="white" v-on="on">
              Company<v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list class="custom-dropdown">
            <v-list-item v-for="(item, index) in company" :key="index">
              <a class="custom-dropdown-title" :href="item.target">
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </a>
            </v-list-item>
          </v-list>
        </v-menu>
        <!--End-Company-Dropdown-->
      </v-toolbar-items>

      <!--Auth-Buttons-->
      <div class="mx-4 hidden-md-and-down">
        <v-btn outlined color="white" class="mr-4" href="https://westore.ai/kw"
          >Sign Up</v-btn
        >
        <v-btn
          class="uppercase"
          color="white"
          href="https://portal.westore.ai/dashboard"
          >MY PORTAL</v-btn
        >
      </div>

      <!--Start-Country-Dropdown-->
      <v-toolbar-items class="hidden-md-and-down">
        <v-menu
          transition="slide-y-transition"
          open-on-hover
          offset-y
          :close-on-click="true"
          content-class="country-menu"
        >
          <template v-slot:activator="{ on }">
            <v-btn class="px-2" :ripple="false" text color="white" v-on="on">
              <div class="d-flex align-items-center">
                <img
                  class="mr-2"
                  style="height: 15px"
                  :src="selectedCountry.image"
                />
                <span class="text-caption font-weight-bold text-uppercase">
                  {{ selectedCountry.title }}</span
                >
                <v-icon class="ml-1" right>mdi-chevron-down</v-icon>
              </div>
            </v-btn>
          </template>
          <v-list class="custom-dropdown">
            <v-list-item
              v-for="(item, index) in country"
              :key="index"
              @click="changeCountry(item.id)"
            >
              <v-list-item-title
                class="custom-dropdown-title d-flex align-items-center"
              >
                <img class="mr-2" height="15" :src="item.image" />
                <span class="text-caption font-weight-bold text-uppercase">
                  {{ item.title }}</span
                >
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <!--End-Country-Dropdown-->

      <div>
        <slot name="theme-bar"></slot>
      </div>
      <v-app-bar-nav-icon
        @click.stop="mobileMenu = !mobileMenu"
        class="hidden-lg-and-up"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer
      color="primary"
      v-model="mobileMenu"
      white
      right
      app
      temporary
    >
      <!-- <v-list nav dense>
        <v-list-item v-for="item in navbar" :key="item.title" :to="item.target">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list> -->
      <template v-slot:append>
        <div class="pa-2" style="margin-bottom: 96px">
          <v-btn block color="white" light outlined href="https://westore.ai/kw"
            >Sign Up</v-btn
          >
          <v-btn
            block
            color="white"
            light
            class="mt-2"
            href="https://portal.westore.ai/dashboard"
            >My Portal</v-btn
          >
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mobileMenu: false,
      loading: false,
      selectedCountry: {
        title: "kuwait",
        image: require("@/assets/flags/kw.png"),
      },

      country: [
        { id: 1, title: "UAE", image: require("@/assets/flags/ae.png") },
        {
          id: 2,
          title: "kuwait",
          image: require("@/assets/flags/kw.png"),
        },
        {
          id: 3,
          title: "Qatar",
          image: require("@/assets/flags/QA.png"),
        },
      ],
    };
  },
  computed: {
    baseURL() {
      return "https://westore.ai/";
    },
    getCountryCode() {
      const countryCodes = {
        uae: "ae",
        kuwait: "kw",
        qatar: "qa",
      };
      return countryCodes[this.selectedCountry.title.toLowerCase()];
    },
    navLinks() {
      const keys = ["products", "technology", "integrations", "clients"];
      const countryCode = this.getCountryCode;
      return keys.reduce((links, key) => {
        links[key] = {
          title: this.capitalizeFirstLetter(key),
          target: `${this.baseURL}${key}-${countryCode}`,
        };
        return links;
      }, {});
    },
    company() {
      const countryCode = this.getCountryCode;
      return [
        { title: "About", target: `${this.baseURL}about-${countryCode}` },
        {
          title: "Contact Us",
          target: `${this.baseURL}contactUs-${countryCode}`,
        },
        {
          title: "Service Provider",
          target: `${this.baseURL}serviceProvider-${countryCode}`,
        },
      ];
    },
    services() {
      const countryCode = this.getCountryCode;
      const services = [
        {
          title: "Warehousing",
          target: `${this.baseURL}warehousing-${countryCode}`,
        },
        {
          title: "Fulfillment",
          target: `${this.baseURL}fulfillment-${countryCode}`,
        },
        { title: "Freight", target: `${this.baseURL}freight-${countryCode}` },
        {
          title: "Transportation",
          target: `${this.baseURL}transportation-${countryCode}`,
        },
        {
          title: "Customs Clearance",
          target: `${this.baseURL}customs-clearance-${countryCode}`,
        },
        {
          title: "Project Management",
          target: `${this.baseURL}projectManagement-${countryCode}`,
        },
      ];

      if (this.selectedCountry.title.toLowerCase() === "uae") {
        services.splice(2, 0, {
          title: "Last Mile Delivery",
          target: `${this.baseURL}lastMile-${countryCode}`,
        });
      }

      return services;
    },
  },
  methods: {
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    changeCountry(id) {
      let country;
      if (id == 1) {
        country = this.ua;
      } else if (id == 2) {
        country = this.kw;
      } else {
        country = this.qa;
      }
      this.selectedCountry = this.country.find((item) => item.id === id);
      // Emit an event with the selected country data
      this.$emit("countryChanged", this.selectedCountry);
      // Simulate a loading state
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  text-transform: uppercase !important;
  border-radius: 4px !important;
}

.v-text-field > .v-input__control > .v-input__slot {
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.drop-mobile {
  .v-list-group__items {
    background-color: #57697b;
    color: #ffffff;
    border-radius: 5px;
  }
}
</style>
<style lang="scss" scoped>
@media screen and (min-width: 1300px) {
  .v-image.theme--light {
    width: 149px !important;
  }
}

/*****Start-Loader*****/
.loader > div {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  margin: 0.5rem;
  animation: loading 2.5s ease-in-out infinite;

  &:nth-child(0) {
    animation-delay: 0s;
  }

  &:nth-child(1) {
    animation-delay: 0.2s;
  }

  &:nth-child(2) {
    animation-delay: 0.4s;
  }

  &:nth-child(3) {
    animation-delay: 0.6s;
  }

  &:nth-child(4) {
    animation-delay: 0.8s;
  }

  &:nth-child(5) {
    animation-delay: 1s;
  }
}

@keyframes loading {
  0%,
  100% {
    transform: scale(0.2);
    background-color: #48bfea;
  }

  42% {
    background-color: #e33064;
  }

  50% {
    transform: scale(1);
    background-color: #48bfea;
  }
}

/*****End-Loader*****/

[id]:before {
  content: unset;
  display: block;
  height: 60px;
  margin: -60px 0 0;
}

.v-list .v-list-item--active {
  background: transparent;
}

.v-list {
  padding-bottom: 0;
}

.service-menu,
.company-menu,
.country-menu {
  contain: initial;
  overflow: visible;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 25px;
    transform: translateY(-90%);
    width: 10px;
    height: 13px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 13px solid #fff;
  }
}

.country-menu {
  img {
    height: 15px;
  }

  &::before {
    right: 5px;
  }
}

.service-menu {
  &::before {
    right: 57px;
  }
}

.custom-dropdown {
  border-radius: 5px;

  .custom-dropdown-title {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 700;
    transition: 0.4s ease-in-out all;
    display: block;
    width: 100%;
    height: 100%;
    text-align: left;
    padding: 5px;

    &:hover {
      color: #e33064;
    }
  }

  .v-list-item:not(:last-of-type) {
    .custom-dropdown-title {
      border-bottom: 2px solid #cbc4c4;
      padding-bottom: 15px;
    }
  }
}
</style>
