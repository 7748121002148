<template>
  <div class="flex justify-between align-center mb-6">
    <div
      class="align-self-start cursor-pointer"
      @click="$router.push({ name: 'Home' })"
    >
      <v-img
        alt="Vuetify Name"
        class="shrink mt-1"
        contain
        min-width="180"
        :src="require('@/assets/logo/logo-dark.png')"
        width="100"
      />
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
