<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#101820"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.6325 12.3875C7.59013 12.3695 7.54988 12.3469 7.5125 12.32L2.2775 7.82C2.07386 7.64583 1.9786 7.37625 2.02762 7.11281C2.07663 6.84937 2.26246 6.63209 2.51512 6.54281C2.76777 6.45354 3.04886 6.50583 3.2525 6.68L7.25 10.115V1.25C7.25 0.835786 7.58579 0.5 8 0.5C8.41421 0.5 8.75 0.835786 8.75 1.25V10.115L12.7625 6.68C13.0773 6.41076 13.5508 6.4477 13.82 6.7625C14.0892 7.0773 14.0523 7.55076 13.7375 7.82L8.4875 12.32C8.45012 12.3469 8.40987 12.3695 8.3675 12.3875L8.2775 12.4475C8.09931 12.5185 7.90069 12.5185 7.7225 12.4475L7.6325 12.3875ZM15.5 14V12.5C15.5 12.0858 15.1642 11.75 14.75 11.75C14.3358 11.75 14 12.0858 14 12.5V13.9475C13.9333 13.9837 13.8583 14.0018 13.7825 14H2.2175C2.14166 14.0018 2.06668 13.9837 2 13.9475V12.5C2 12.0858 1.66421 11.75 1.25 11.75C0.835786 11.75 0.5 12.0858 0.5 12.5V14C0.567994 14.8845 1.33186 15.5517 2.2175 15.5H13.7825C14.6681 15.5517 15.432 14.8845 15.5 14Z"
      fill="#101820"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
