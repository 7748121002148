<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2773_22009)">
      <path
        d="M12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24ZM10.5 9.75C10.5 10.992 9.828 12 9 12C8.172 12 7.5 10.992 7.5 9.75C7.5 8.508 8.172 7.5 9 7.5C9.828 7.5 10.5 8.508 10.5 9.75ZM6.4275 14.3505C6.59976 14.251 6.80447 14.2241 6.9966 14.2756C7.18873 14.3271 7.35254 14.4527 7.452 14.625C7.91267 15.4235 8.57558 16.0866 9.37399 16.5474C10.1724 17.0082 11.0781 17.2505 12 17.25C12.9219 17.2505 13.8276 17.0082 14.626 16.5474C15.4244 16.0866 16.0873 15.4235 16.548 14.625C16.5969 14.539 16.6623 14.4636 16.7405 14.4029C16.8186 14.3423 16.908 14.2978 17.0035 14.2719C17.0989 14.246 17.1986 14.2392 17.2967 14.2519C17.3947 14.2647 17.4893 14.2967 17.575 14.3462C17.6607 14.3956 17.7357 14.4615 17.7958 14.5401C17.8558 14.6187 17.8998 14.7084 17.9251 14.804C17.9504 14.8996 17.9565 14.9993 17.9431 15.0973C17.9297 15.1953 17.897 15.2897 17.847 15.375C17.2547 16.4015 16.4024 17.2539 15.376 17.8464C14.3496 18.4389 13.1852 18.7505 12 18.75C10.8148 18.7505 9.65045 18.4389 8.62401 17.8464C7.59758 17.2539 6.74532 16.4015 6.153 15.375C6.05355 15.2027 6.0266 14.998 6.07808 14.8059C6.12955 14.6138 6.25525 14.45 6.4275 14.3505ZM15 12C14.172 12 13.5 10.992 13.5 9.75C13.5 8.508 14.172 7.5 15 7.5C15.828 7.5 16.5 8.508 16.5 9.75C16.5 10.992 15.828 12 15 12Z"
        fill="#65CD54"
      />
    </g>
    <defs>
      <clipPath id="clip0_2773_22009">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
