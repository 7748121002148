
import wsNavbar from "./navbar.vue";
import wsFooter from "./footer.vue";

export default {
  components: { wsNavbar, wsFooter },

  data() {
    return {
      selectedCountry: {
        title: "kuwait",
        image: require("@/assets/flags/kw.png"),
      },
    };
  },

  methods: {
    updateSelectedCountry(country) {
      this.selectedCountry = country;
    },
  },
};
