// getters types
export const GET_USER_TOKEN = "auth/GET_USER_TOKEN";
export const VALIDATE_USER_TOKEN = "auth/GET_USER_DATA";
export const GET_IS_CREATE_FLOW = "create/IS_CREATE_FLOW";
// mutations types

export const SET_USER_TOKEN = "auth/SET_USER_TOKEN";
export const LOGOUT_USER = "auth/LOGOUT_USER";
export const SET_IS_CREATE_FLOW = "create/SET_IS_CREATE_FLOW";

// ations types
export const GET_TOKEN = "auth/GET_TOKEN";
