<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="7"
    viewBox="0 0 44 7"
    fill="none"
  >
    <path
      d="M43.6667 3.5C43.6667 2.02724 42.4728 0.833333 41 0.833333C39.5272 0.833333 38.3333 2.02724 38.3333 3.5C38.3333 4.97276 39.5272 6.16667 41 6.16667C42.4728 6.16667 43.6667 4.97276 43.6667 3.5ZM41 3L6 3V4L41 4V3Z"
      fill="#E33065"
    />
    <path
      d="M0.333333 3.5C0.333333 2.02724 1.52724 0.833333 3 0.833333C4.47276 0.833333 5.66667 2.02724 5.66667 3.5C5.66667 4.97276 4.47276 6.16667 3 6.16667C1.52724 6.16667 0.333333 4.97276 0.333333 3.5ZM3 3L38 3V4L3 4V3Z"
      fill="#E33065"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
