<template>
  <v-row>
    <v-col
      v-for="n in 4"
      class="mt-5"
      :key="n"
      md="12"
      sm="12"
      xs="12"
      lg="12"
      xl="12"
    >
      <v-skeleton-loader v-bind="attrs" type="heading"></v-skeleton-loader>
      <v-skeleton-loader v-bind="attrs" type="text"></v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    attrs: {
      class: "mb-6",
      boilerplate: false,
      elevation: 0,
    },
  }),
};
</script>

<style></style>
