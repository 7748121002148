<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2773_22016)">
      <path
        d="M12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24ZM10.5 9.75C10.5 10.992 9.828 12 9 12C8.172 12 7.5 10.992 7.5 9.75C7.5 8.508 8.172 7.5 9 7.5C9.828 7.5 10.5 8.508 10.5 9.75ZM6.4275 18.6495C6.25525 18.55 6.12955 18.3862 6.07808 18.1941C6.0266 18.002 6.05355 17.7973 6.153 17.625C6.74532 16.5985 7.59758 15.7461 8.62401 15.1536C9.65045 14.5611 10.8148 14.2495 12 14.25C13.1851 14.2498 14.3494 14.5615 15.3758 15.154C16.4021 15.7464 17.2545 16.5987 17.847 17.625C17.9449 17.7972 17.9707 18.0011 17.9188 18.1922C17.8669 18.3833 17.7415 18.5462 17.57 18.6452C17.3985 18.7442 17.1948 18.7714 17.0033 18.7208C16.8118 18.6702 16.6482 18.5459 16.548 18.375C16.0873 17.5765 15.4244 16.9134 14.626 16.4526C13.8276 15.9918 12.9219 15.7495 12 15.75C11.0781 15.7495 10.1724 15.9918 9.37399 16.4526C8.57558 16.9134 7.91267 17.5765 7.452 18.375C7.35254 18.5473 7.18873 18.6729 6.9966 18.7244C6.80447 18.7759 6.59976 18.749 6.4275 18.6495ZM15 12C14.172 12 13.5 10.992 13.5 9.75C13.5 8.508 14.172 7.5 15 7.5C15.828 7.5 16.5 8.508 16.5 9.75C16.5 10.992 15.828 12 15 12Z"
        fill="#CB3243"
      />
    </g>
    <defs>
      <clipPath id="clip0_2773_22016">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
