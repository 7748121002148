<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.3335 0.333008H11.6668C12.7714 0.333008 13.6668 1.22844 13.6668 2.33301V11.6663C13.6668 12.7709 12.7714 13.6663 11.6668 13.6663H2.3335C1.22893 13.6663 0.333496 12.7709 0.333496 11.6663V2.33301C0.333496 1.22844 1.22893 0.333008 2.3335 0.333008ZM2.3335 12.333H11.6668C11.9248 12.3311 12.1585 12.1805 12.2668 11.9463L5.10016 6.57967L1.66683 10.5797V11.6663C1.66683 12.0345 1.96531 12.333 2.3335 12.333ZM12.3335 10.333L8.3335 7.30634L9.74683 5.89301L12.3335 7.98634V10.333ZM10.0802 4.47967L12.3335 6.27967V2.33301C12.3335 1.96482 12.035 1.66634 11.6668 1.66634H2.3335C1.96531 1.66634 1.66683 1.96482 1.66683 2.33301V8.53301L4.4935 5.23301C4.72205 4.96578 5.11886 4.92201 5.40016 5.13301L7.1935 6.52634L9.1935 4.52634C9.43419 4.2876 9.81573 4.26752 10.0802 4.47967Z"
      fill="#48BEEA"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
