<template>
  <svg
    width="89"
    height="68"
    viewBox="0 0 89 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.8564 49.9611H35.3925V55.8364H28.8564V49.9611Z"
      fill="url(#paint0_angular_347_2993)"
    />
    <path
      d="M88.3047 12.1407C88.9167 10.689 88.2214 9.01652 86.747 8.40817C59.5042 -2.80272 29.4948 -2.80272 2.25201 8.40817C0.791665 9.01658 0.0964504 10.6751 0.69436 12.1407C1.30641 13.5923 2.97481 14.2974 4.44922 13.6891C30.2727 3.05849 58.725 3.05849 84.5502 13.6891C84.9119 13.8412 85.2871 13.9105 85.6488 13.9105C86.7749 13.8959 87.846 13.2328 88.305 12.1407L88.3047 12.1407Z"
      fill="url(#paint1_angular_347_2993)"
    />
    <path
      d="M0 61.9178H4.36636V68H0V61.9178Z"
      fill="url(#paint2_angular_347_2993)"
    />
    <path
      d="M84.6338 61.9178H89.0001V68H84.6338V61.9178Z"
      fill="url(#paint3_angular_347_2993)"
    />
    <path
      d="M53.6079 49.9611H60.1439V55.8364H53.6079V49.9611Z"
      fill="url(#paint4_angular_347_2993)"
    />
    <path
      d="M63.0091 58.6969H50.7297V49.9603H47.4619V67.9858H66.2903V49.9603H63.0086L63.0091 58.6969Z"
      fill="url(#paint5_angular_347_2993)"
    />
    <path
      d="M38.2706 58.6969H25.9912V49.9603H22.7095V67.9858H41.5379V49.9603H38.2701L38.2706 58.6969Z"
      fill="url(#paint6_angular_347_2993)"
    />
    <path
      d="M7.23193 15.6647V67.9867H17.745V31.077H71.2571V67.9867H81.7702L81.7709 15.6647C69.7834 10.9647 57.2537 8.5592 44.5025 8.5592C31.7506 8.5592 19.2347 10.9642 7.23421 15.6647H7.23193ZM33.5012 25.0504H25.9639V17.558H33.5012V25.0504ZM55.501 17.558H63.0382V25.0504H55.501V17.558ZM48.2697 25.0504H40.7325V17.558H48.2697V25.0504Z"
      fill="url(#paint7_angular_347_2993)"
    />
    <defs>
      <radialGradient
        id="paint0_angular_347_2993"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(32.1244 52.8987) rotate(90) scale(2.93762 3.268)"
      >
        <stop stop-color="#48BEEA" />
        <stop offset="0.177083" stop-color="#48BEEA" />
        <stop offset="0.708333" stop-color="#E33065" />
      </radialGradient>
      <radialGradient
        id="paint1_angular_347_2993"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(44.5034 6.95527) rotate(90) scale(6.95527 44.0234)"
      >
        <stop stop-color="#48BEEA" />
        <stop offset="0.177083" stop-color="#48BEEA" />
        <stop offset="0.708333" stop-color="#E33065" />
      </radialGradient>
      <radialGradient
        id="paint2_angular_347_2993"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(2.18318 64.9589) rotate(90) scale(3.04108 2.18318)"
      >
        <stop stop-color="#48BEEA" />
        <stop offset="0.177083" stop-color="#48BEEA" />
        <stop offset="0.708333" stop-color="#E33065" />
      </radialGradient>
      <radialGradient
        id="paint3_angular_347_2993"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(86.817 64.9589) rotate(90) scale(3.04108 2.18318)"
      >
        <stop stop-color="#48BEEA" />
        <stop offset="0.177083" stop-color="#48BEEA" />
        <stop offset="0.708333" stop-color="#E33065" />
      </radialGradient>
      <radialGradient
        id="paint4_angular_347_2993"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(56.8759 52.8987) rotate(90) scale(2.93762 3.26801)"
      >
        <stop stop-color="#48BEEA" />
        <stop offset="0.177083" stop-color="#48BEEA" />
        <stop offset="0.708333" stop-color="#E33065" />
      </radialGradient>
      <radialGradient
        id="paint5_angular_347_2993"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(56.8761 58.9731) rotate(90) scale(9.01278 9.41419)"
      >
        <stop stop-color="#48BEEA" />
        <stop offset="0.177083" stop-color="#48BEEA" />
        <stop offset="0.708333" stop-color="#E33065" />
      </radialGradient>
      <radialGradient
        id="paint6_angular_347_2993"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(32.1237 58.9731) rotate(90) scale(9.01278 9.41419)"
      >
        <stop stop-color="#48BEEA" />
        <stop offset="0.177083" stop-color="#48BEEA" />
        <stop offset="0.708333" stop-color="#E33065" />
      </radialGradient>
      <radialGradient
        id="paint7_angular_347_2993"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(44.5014 38.2729) rotate(90) scale(29.7137 37.2695)"
      >
        <stop stop-color="#48BEEA" />
        <stop offset="0.177083" stop-color="#48BEEA" />
        <stop offset="0.708333" stop-color="#E33065" />
      </radialGradient>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
