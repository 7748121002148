import Vue from "vue";
import vueRouter from "vue-router";
import VueMeta from "vue-meta";
import { Route, NavigationGuardNext } from "vue-router";
import { routes } from "./routes";
import * as types from "@/state/types";
import { store } from "@/state/store";

Vue.use(vueRouter);
Vue.use(VueMeta);
const router = new vueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
router.beforeResolve((routeTo, routeFrom, next) => {
  for (const route of routeTo.matched) {
    if (route.meta && route.meta.beforeResolve) {
      route.meta.beforeResolve(routeTo, routeFrom, next);
    } else {
      next();
    }
  }
});
router.beforeEach(
  (routeTo: Route, routeFrom: Route, next: NavigationGuardNext) => {
    const authRequired = routeTo.matched.some(
      (route) => route.meta.authRequired
    );

    if (!authRequired) return next();

    if (store.getters[types.VALIDATE_USER_TOKEN]) {
      next();
    } else {
      next({ name: "Login", query: { redirectFrom: routeTo.fullPath } });
    }
  }
);
export default router;
