
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import * as types from "@/state/types";
export default Vue.extend({
  computed: {
    ...mapGetters({
      isCreate: types.GET_IS_CREATE_FLOW,
    }),
  },
  methods: {
    ...mapMutations({
      endCreateProcess: types.SET_IS_CREATE_FLOW,
    }),

    handleClick() {
      if (this.$route.name === "Home" && this.isCreate) {
        this.endCreateProcess(false);
      } else {
        this.$router.back();
      }
    },
  },
});
