import * as types from "../types";
import { adminApi } from "@/api";
import { ActionContext } from "vuex";
import router from "@/router";
import { handleLocalStorage, clearLocalStorage } from "@/utils";
import { IAuthState } from "./module.interface";

const state: IAuthState = {
  token: localStorage.getItem("token") || "",
};
const getters = {
  [types.GET_USER_TOKEN]: (state: IAuthState) => {
    return state.token;
  },
  [types.VALIDATE_USER_TOKEN]: ({ token }: IAuthState) => {
    return token ? true : false;
  },
};
const mutations = {
  [types.SET_USER_TOKEN]: (state: IAuthState, token: string) => {
    state.token = token;
  },
  [types.LOGOUT_USER]: (state: IAuthState) => {
    router.replace("/login");

    state.token = "";
    clearLocalStorage();
  },
};
const actions = {
  [types.GET_TOKEN]: async ({ commit }: ActionContext<IAuthState, any>) => {
    try {
      await new Promise((resolve, reject) => {
        if (window.location.search) {
          const token = decodeURIComponent(
            window.location.search.split("=")[1]
          );

          handleLocalStorage(token);
          commit(types.SET_USER_TOKEN, token);
          window.location.search = "";
          resolve(router.replace({ name: "Home" }));
        } else {
          reject("unauthorized");
        }
      });
    } catch (error) {
      window.location.href = "https://admin.westore.ai/login";
    }

    // commit(types.SET_USER_TOKEN, data);
    //  router.replace(`/home/${data.localId}`);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
