<template>
  <v-footer dark padless>
    <v-card flat tile color="primary" width="100%">
      <v-container fluid class="py-9">
        <v-row>
          <!-- Social -->
          <v-col cols="12" md="12" lg="3" class="text-center">
            <a href="https://westore.ai">
              <v-img
                eager
                alt="WeStore"
                class="shrink mr-2 d-inline-block"
                :src="require('@/assets/logo/logo-white.png')"
                transition="scale-transition"
                width="210"
              />
            </a>
            <div>
              <v-btn
                v-for="si in socialIcons"
                :key="si.url"
                class="mx-2 white--text"
                icon
                :href="si.url"
                target="_blank"
              >
                <v-icon size="24px">{{ si.icon }}</v-icon>
              </v-btn>
            </div>
          </v-col>

          <!-- Footer Links -->
          <v-col
            v-for="fl in footerLinks"
            :key="fl.head.title"
            cols="12"
            md="3"
            :lg="fl.cols || 2"
          >
            <v-list dense color="primary" class="white--text">
              <v-list-item-group active-class="secondary--text">
                <v-list-item :to="fl.head.to">
                  <h3>{{ fl.head.title }}</h3>
                </v-list-item>
                <v-list-item
                  v-for="fll in fl.links"
                  :key="fll.title"
                  :href="fll.url"
                >
                  <v-list-item-icon v-if="fll.icon" class="hidden-md-and-down">
                    <v-icon>{{ fll.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>{{ fll.title }}</v-list-item-content>
                </v-list-item>
                <v-list-item v-for="pdf in fl.pdfs" :key="pdf.name">
                  <a class="white--text" :href="pdf.url" target="_blank">{{
                    pdf.name
                  }}</a>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-container>

      <v-divider dark></v-divider>

      <v-card-text class="white--text text-center">
        {{ new Date().getFullYear() }} — <strong>WeStore</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "ws-footer",
  data() {
    return {
      socialIcons: [
        { url: "https://facebook.com/WeStoreAi", icon: "mdi-facebook" },
        { url: "https://www.instagram.com/westore.ai", icon: "mdi-instagram" },
        { url: "https://www.twitter.com/westoretech", icon: "mdi-twitter" },
        {
          url: "https://www.linkedin.com/company/westore-ai/",
          icon: "mdi-linkedin",
        },
      ],
      footerLinks: [
        {
          head: { title: "Company" },
          links: [],
          pdfs: [
            {
              name: "Quality Policy",
              url: "https://drive.google.com/file/d/1Z2U_GGtAgaXvqkd2oBwxLufjucv2-7dP/view?usp=sharing",
            },
            {
              name: "Health and Safety Policy",
              url: "https://drive.google.com/file/d/1orkdYhwGL7JMZdsIFcBZkm1oUqUTN9l_/view?usp=sharing",
            },
            {
              name: "Environmental Policy",
              url: "https://drive.google.com/file/d/1DF72tqbN8Y05fJBhUwtQw8pvK9zeMznK/view?usp=sharing",
            },
          ],
        },
        {
          head: { title: "Services" },
          links: [],
        },
        {
          head: { title: "Resources" },
          links: [],
        },
        {
          cols: 3,
          head: { title: "Contact" },
          links: [],
        },
      ],
    };
  },
  props: {
    selectedCountry: Object,
  },
  watch: {
    selectedCountry: {
      handler(newValue) {
        this.updateContactLinks(newValue);
        this.updateServiceLinks(newValue);
        this.updateResourceLinks(newValue);
        this.updateCompanyLinks(newValue);
      },
      immediate: true,
    },
  },
  methods: {
    updateLinks(selectedCountry) {
      this.updateContactLinks(selectedCountry);
      this.updateCompanyLinks(selectedCountry);
      this.updateServiceLinks(selectedCountry);
      this.updateResourceLinks(selectedCountry);
    },
    updateContactLinks(selectedCountry) {
      const countryCodeMap = {
        UAE: {
          countryCode: "ae",
          phoneNumber: "+971585989361",
          address:
            "30th floor, Office 30-11, Reef Tower Jumeirah Lakes Towers Dubai, UAE",
          faxNumber: null,
        },
        Qatar: {
          countryCode: "qa",
          phoneNumber: "+97444131770 / 44858600",
          address:
            "Zone no.55, Al Amir Street no. 251, Building no. 125 P.O.Box: 22403, Doha, Qatar",
          faxNumber: "+97444369529",
        },
        default: {
          countryCode: "kw",
          phoneNumber: "+96522277313",
          address:
            "14th Floor, Al-Soor Tower Al-Soor Street P.O. Box 5110, Safat 13052 Kuwait",
          faxNumber: null,
        },
      };
      const { countryCode, phoneNumber, address, faxNumber } =
        countryCodeMap[selectedCountry.title] || countryCodeMap["default"];

      const contactLinks = [
        { title: phoneNumber, url: `tel:${phoneNumber}`, icon: "mdi-phone" },
        {
          title: "connect@westore.ai",
          url: "mailto:connect@westore.ai",
          icon: "mdi-email",
        },
        { title: address, icon: "mdi-map-marker" },
      ];

      if (faxNumber) {
        contactLinks.push({ title: faxNumber, icon: "mdi-fax" });
      }

      this.$set(this.footerLinks[3], "links", contactLinks);
    },
    updateCompanyLinks(selectedCountry) {
      this.updateLinksByType(selectedCountry, 0, this.getCompanyLinks);
    },
    updateServiceLinks(selectedCountry) {
      this.updateLinksByType(selectedCountry, 1, this.getServiceLinks);
    },
    updateResourceLinks(selectedCountry) {
      this.updateLinksByType(selectedCountry, 2, this.getResourceLinks);
    },
    updateLinksByType(selectedCountry, index, linkGenerator) {
      const countryCodeMap = { UAE: "ae", Qatar: "qa", default: "kw" };
      const countryCode =
        countryCodeMap[selectedCountry.title] || countryCodeMap["default"];
      this.$set(this.footerLinks[index], "links", linkGenerator(countryCode));
    },
    getServiceLinks(countryCode) {
      const commonLinks = [
        {
          title: "Warehousing",
          url: `https://westore.ai/warehousing-${countryCode}`,
        },
        {
          title: "Fulfillment",
          url: `https://westore.ai/fulfillment-${countryCode}`,
        },
        { title: "Freight", url: `https://westore.ai/freight-${countryCode}` },
        {
          title: "Transportation",
          url: `https://westore.ai/transportation-${countryCode}`,
        },
        {
          title: "Customs Clearance",
          url: `https://westore.ai/customs-clearance-${countryCode}`,
        },
        {
          title: "Project Management",
          url: `https://westore.ai/projectManagement-${countryCode}`,
        },
      ];
      if (countryCode === "ae") {
        commonLinks.splice(2, 0, {
          title: "Last Mile Delivery",
          url: `https://westore.ai/lastMile-${countryCode}`,
        });
      }
      return commonLinks;
    },
    getResourceLinks(countryCode) {
      return [
        { title: "Help", url: `https://westore.ai/help-${countryCode}` },
        { title: "FAQs", url: `https://westore.ai/faqs-${countryCode}` },
        { title: "Schedule a Demo", url: "https://westore.ai/#get-a-call" },
        {
          title: "List Your Warehouse",
          url: `https://westore.ai/warehousing-${countryCode}#list-your-warehouse`,
        },
      ];
    },
    getCompanyLinks(countryCode) {
      return [
        { title: "About", url: `https://westore.ai/about-${countryCode}` },
        {
          title: "Career",
          url: `https://westore.ai/about-${countryCode}#career`,
        },
        {
          title: "Terms of Service",
          url: `https://westore.ai/terms-of-service-${countryCode}`,
        },
        {
          title: "Privacy Policy",
          url: `https://westore.ai/privacy-policy-${countryCode}`,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.v-footer h3 {
  font-size: 1.75rem;
  cursor: default;
}
</style>
