<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="#101820"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.6325 4.6125C8.59013 4.63049 8.54988 4.65313 8.5125 4.68L3.2775 9.18C3.07386 9.35417 2.9786 9.62375 3.02762 9.88719C3.07663 10.1506 3.26246 10.3679 3.51512 10.4572C3.76777 10.5465 4.04886 10.4942 4.2525 10.32L8.25 6.885V15.75C8.25 16.1642 8.58579 16.5 9 16.5C9.41421 16.5 9.75 16.1642 9.75 15.75V6.885L13.7625 10.32C14.0773 10.5892 14.5508 10.5523 14.82 10.2375C15.0892 9.9227 15.0523 9.44924 14.7375 9.18L9.4875 4.68C9.45012 4.65313 9.40987 4.63049 9.3675 4.6125L9.2775 4.5525C9.09931 4.48153 8.90069 4.48153 8.7225 4.5525L8.6325 4.6125ZM16.5 3V4.5C16.5 4.91421 16.1642 5.25 15.75 5.25C15.3358 5.25 15 4.91421 15 4.5V3.0525C14.9333 3.01633 14.8583 2.99824 14.7825 3H3.2175C3.14166 2.99824 3.06668 3.01633 3 3.0525V4.5C3 4.91421 2.66421 5.25 2.25 5.25C1.83579 5.25 1.5 4.91421 1.5 4.5V3C1.56799 2.11547 2.33186 1.44833 3.2175 1.5H14.7825C15.6681 1.44833 16.432 2.11547 16.5 3Z"
      fill="#101820"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
