<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1888_7513)">
      <path
        d="M11.25 10.1812C11.9959 10.1812 12.7113 9.88484 13.2387 9.35739C13.7662 8.82994 14.0625 8.11457 14.0625 7.36865C14.0625 6.62273 13.7662 5.90736 13.2387 5.37991C12.7113 4.85247 11.9959 4.55615 11.25 4.55615C10.5041 4.55615 9.78871 4.85247 9.26126 5.37991C8.73382 5.90736 8.4375 6.62273 8.4375 7.36865C8.4375 8.11457 8.73382 8.82994 9.26126 9.35739C9.78871 9.88484 10.5041 10.1812 11.25 10.1812Z"
        fill="white"
      />
      <path
        d="M2.25 1.125C1.65326 1.125 1.08097 1.36205 0.65901 1.78401C0.237053 2.20597 0 2.77826 0 3.375L0 13.5C0 14.0967 0.237053 14.669 0.65901 15.091C1.08097 15.5129 1.65326 15.75 2.25 15.75H15.75C16.3467 15.75 16.919 15.5129 17.341 15.091C17.7629 14.669 18 14.0967 18 13.5V3.375C18 2.77826 17.7629 2.20597 17.341 1.78401C16.919 1.36205 16.3467 1.125 15.75 1.125H2.25ZM1.125 3.375C1.125 3.07663 1.24353 2.79048 1.4545 2.5795C1.66548 2.36853 1.95163 2.25 2.25 2.25H4.5V4.5H1.125V3.375ZM5.625 14.625V2.25H15.75C16.0484 2.25 16.3345 2.36853 16.5455 2.5795C16.7565 2.79048 16.875 3.07663 16.875 3.375V13.5C16.875 13.8206 16.74 14.1109 16.5262 14.3156C15.9187 12.9308 14.4248 11.25 11.25 11.25C7.83337 11.25 6.36188 13.1962 5.85225 14.625H5.625ZM1.125 12.375H4.5V14.625H2.25C1.95163 14.625 1.66548 14.5065 1.4545 14.2955C1.24353 14.0845 1.125 13.7984 1.125 13.5V12.375ZM4.5 11.25H1.125V9H4.5V11.25ZM4.5 7.875H1.125V5.625H4.5V7.875Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1888_7513">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
