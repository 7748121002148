<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="7"
    viewBox="0 0 38 7"
    fill="none"
  >
    <path
      d="M37.6667 3.5C37.6667 2.02724 36.4728 0.833333 35 0.833333C33.5272 0.833333 32.3333 2.02724 32.3333 3.5C32.3333 4.97276 33.5272 6.16667 35 6.16667C36.4728 6.16667 37.6667 4.97276 37.6667 3.5ZM35 3L0 3V4L35 4V3Z"
      fill="#48BEEA"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
