<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18ZM13 12C13.4482 12.0234 13.857 11.7454 14 11.32C14.0836 11.0614 14.0583 10.78 13.9301 10.5404C13.8018 10.3008 13.5815 10.1238 13.32 10.05L11 9.28V5C11 4.44772 10.5523 4 10 4C9.44771 4 9 4.44772 9 5V10C8.99889 10.4299 9.27269 10.8124 9.68 10.95L12.68 11.95C12.784 11.9805 12.8916 11.9973 13 12Z"
      fill="#48BEEA"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
