<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="35"
    viewBox="0 0 42 35"
    fill="none"
  >
    <path
      d="M23.5873 29.0135H5.2197C4.53212 29.0128 3.87288 28.7257 3.38656 28.2161C2.90063 27.7061 2.62695 27.0146 2.6263 26.2934H21.4275C21.9051 26.2934 22.292 25.8876 22.292 25.3868C22.292 24.8859 21.9051 24.4801 21.4275 24.4801H2.6263V4.53335C2.62695 3.81219 2.90062 3.12076 3.38656 2.61069C3.87281 2.10103 4.53209 1.81398 5.2197 1.8133H32.8822C33.5698 1.81398 34.2291 2.10102 34.7154 2.61069C35.2013 3.12069 35.475 3.81216 35.4756 4.53335V15.6401C35.4756 16.141 35.8625 16.5467 36.3401 16.5467C36.8177 16.5467 37.2045 16.141 37.2045 15.6401V4.53335C37.2033 3.33154 36.7472 2.17929 35.9371 1.32935C35.1267 0.479688 34.028 0.00138159 32.8823 0H5.21973C4.07388 0.00134921 2.97528 0.479645 2.16492 1.32935C1.35482 2.17936 0.898778 3.33162 0.897461 4.53335V26.2934C0.898747 27.4952 1.35477 28.6475 2.16492 29.4974C2.97535 30.3471 4.07396 30.8254 5.21973 30.8268H23.5873C24.0649 30.8268 24.4518 30.421 24.4518 29.9201C24.4518 29.4192 24.0649 29.0135 23.5873 29.0135Z"
      fill="#48BEEA"
    />
    <path
      d="M14.7743 7.0408C14.3141 6.78718 13.7473 6.78718 13.2869 7.0408C12.8267 7.29441 12.5433 7.76324 12.543 8.27047V17.0746C12.5421 17.4089 12.6647 17.7328 12.8896 17.989C13.1144 18.2454 13.4269 18.4175 13.7718 18.4751C14.1166 18.5326 14.472 18.472 14.7743 18.3038L22.7599 13.9025C23.0628 13.7354 23.2943 13.472 23.414 13.1583C23.5335 12.8444 23.5335 12.5007 23.414 12.1867C23.2943 11.873 23.0628 11.6097 22.7599 11.4426L14.7743 7.0408ZM14.1088 16.9448V8.39983L21.8595 12.6724L14.1088 16.9448Z"
      fill="#48BEEA"
    />
    <path
      d="M31.3815 13.7012C28.5653 13.7012 25.8649 14.8197 23.8736 16.8111C21.8823 18.8025 20.7632 21.5033 20.7637 24.3195C20.7637 27.1357 21.8822 29.8367 23.8736 31.8279C25.8649 33.8192 28.5658 34.9379 31.382 34.9374C34.1982 34.9374 36.8991 33.8188 38.8904 31.827C40.8812 29.8357 41.9999 27.1348 41.9999 24.3186C41.9966 21.5038 40.8771 18.8047 38.8863 16.8143C36.8959 14.824 34.1968 13.7039 31.3815 13.7012ZM31.3815 32.4386C29.228 32.4386 27.1628 31.583 25.6399 30.0602C24.117 28.5378 23.262 26.4721 23.262 24.3186C23.262 22.165 24.1171 20.0999 25.6399 18.5769C27.1628 17.054 29.228 16.199 31.3815 16.199C33.5351 16.199 35.6007 17.0541 37.1232 18.5769C38.6461 20.0999 39.5016 22.165 39.5016 24.3186C39.4992 26.4716 38.6432 28.5359 37.1208 30.0578C35.5988 31.5802 33.5345 32.4362 31.3815 32.4386Z"
      fill="#48BEEA"
    />
    <path
      d="M31.5282 19.4029C31.5246 19.3997 31.52 19.3978 31.5158 19.3946C31.5028 19.3827 31.4879 19.3734 31.474 19.3629C31.4523 19.3473 31.4291 19.3323 31.4063 19.3184C31.3913 19.3091 31.3769 19.2991 31.3614 19.2903C31.3476 19.283 31.332 19.2773 31.3185 19.2702C31.2942 19.2589 31.2699 19.2501 31.2451 19.2407C31.2276 19.2339 31.2101 19.2263 31.1923 19.2206C31.1779 19.2164 31.1624 19.2138 31.148 19.2101C31.1231 19.2039 31.0974 19.1997 31.072 19.1957C31.0534 19.1926 31.0348 19.1883 31.0161 19.1863C30.9992 19.1843 30.9811 19.1852 30.9645 19.1843C30.9515 19.1838 30.9396 19.1807 30.9264 19.1807C30.9134 19.1807 30.9015 19.1838 30.8883 19.1843C30.8713 19.1849 30.8533 19.1843 30.8366 19.1863C30.818 19.1883 30.7993 19.1926 30.7807 19.1957C30.7553 19.1999 30.7296 19.2045 30.7048 19.2101C30.6904 19.2138 30.6748 19.2164 30.6604 19.2206C30.6424 19.2263 30.6249 19.2337 30.6077 19.2407C30.5828 19.2501 30.5585 19.2589 30.5343 19.2702C30.5204 19.277 30.5058 19.2833 30.4914 19.2903C30.4764 19.2991 30.4614 19.3091 30.4459 19.3184C30.4228 19.3323 30.4005 19.347 30.3793 19.3629C30.3649 19.3734 30.3499 19.383 30.3369 19.3946C30.3327 19.3978 30.3282 19.3997 30.3245 19.4029L26.5756 22.5814C26.1816 22.9152 26.1316 23.5064 26.4647 23.9021C26.649 24.122 26.913 24.2345 27.1783 24.2345C27.3912 24.2345 27.6055 24.162 27.7805 24.0132L29.9925 22.1373V29.1832C29.9925 29.7007 30.4109 30.1208 30.9263 30.1208C31.4418 30.1208 31.8602 29.7007 31.8602 29.1832V22.1381L34.0722 24.014C34.2473 24.1628 34.4612 24.2353 34.6744 24.2353C34.9403 24.2353 35.2042 24.1222 35.388 23.9029C35.7211 23.5072 35.6714 22.9158 35.2771 22.5822L31.5282 19.4029Z"
      fill="#48BEEA"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
