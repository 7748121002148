<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.7131 11.7131C9.09726 14.2396 4.93922 14.2035 2.36766 11.6319C-0.203897 9.06035 -0.240029 4.90231 2.28645 2.28645C4.90231 -0.240029 9.06035 -0.203897 11.6319 2.36766C14.2035 4.93922 14.2396 9.09726 11.7131 11.7131ZM9.83979 5.10645L7.93979 6.99979L9.83979 8.89312C9.96599 9.0183 10.037 9.18869 10.037 9.36645C10.037 9.54421 9.96599 9.71461 9.83979 9.83979C9.71397 9.96458 9.54365 10.0341 9.36645 10.0331C9.18925 10.0341 9.01893 9.96458 8.89312 9.83979L6.99979 7.93979L5.10645 9.83979C4.98064 9.96458 4.81032 10.0341 4.63312 10.0331C4.45592 10.0341 4.2856 9.96458 4.15979 9.83979C4.03358 9.71461 3.96259 9.54421 3.96259 9.36645C3.96259 9.18869 4.03358 9.0183 4.15979 8.89312L6.05979 6.99979L4.15979 5.10645C3.99068 4.93735 3.92464 4.69087 3.98653 4.45987C4.04843 4.22886 4.22886 4.04843 4.45987 3.98653C4.69087 3.92464 4.93735 3.99068 5.10645 4.15979L6.99979 6.05979L8.89312 4.15979C9.06223 3.99068 9.3087 3.92464 9.5397 3.98653C9.77071 4.04843 9.95114 4.22886 10.013 4.45987C10.0749 4.69087 10.0089 4.93735 9.83979 5.10645ZM3.23205 3.22925C5.31391 1.14661 8.68971 1.14536 10.7731 3.22645C11.7829 4.22241 12.3513 5.58151 12.3513 6.99979C12.3513 8.41806 11.7829 9.77716 10.7731 10.7731C8.68971 12.8542 5.31391 12.853 3.23205 10.7703C1.15018 8.68769 1.15018 5.31188 3.23205 3.22925Z"
      fill="#EC1C11"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
