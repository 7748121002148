<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.7452 10.6875L16.3727 15.315H16.3952C16.6106 15.5286 16.6761 15.8509 16.5612 16.1316C16.4462 16.4124 16.1735 16.5962 15.8702 16.5975C15.6698 16.5967 15.478 16.5156 15.3377 16.3725L10.7102 11.745L8.01771 14.4375C7.72518 14.7283 7.25274 14.7283 6.96021 14.4375L5.58021 13.0575C5.15569 12.6323 5.02584 11.9949 5.25021 11.4375L2.31021 8.4375C1.88656 8.01532 1.64844 7.44184 1.64844 6.84375C1.64844 6.24566 1.88656 5.67218 2.31021 5.25L5.31021 2.25C5.73239 1.82635 6.30587 1.58823 6.90396 1.58823C7.50205 1.58823 8.07553 1.82635 8.49771 2.25L11.4377 5.25C11.9907 5.01988 12.6275 5.14077 13.0577 5.5575L14.4377 6.9375C14.7285 7.23003 14.7285 7.70247 14.4377 7.995L11.7452 10.6875ZM6.62271 12L7.50021 12.8475L12.8477 7.5L12.0002 6.6225L11.8877 6.75C11.7469 6.89199 11.5552 6.97185 11.3552 6.97185C11.1552 6.97185 10.9635 6.89199 10.8227 6.75L7.40271 3.375C7.11018 3.08421 6.63774 3.08421 6.34521 3.375L3.34521 6.375C3.05442 6.66753 3.05442 7.13997 3.34521 7.4325L6.75021 10.8225C6.8922 10.9633 6.97206 11.155 6.97206 11.355C6.97206 11.555 6.8922 11.7467 6.75021 11.8875L6.62271 12Z"
      fill="#48BEEA"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
