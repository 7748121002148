<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.00016 13.6667C3.31826 13.6667 0.333496 10.6819 0.333496 7.00001C0.333496 3.31811 3.31826 0.333344 7.00016 0.333344C8.76827 0.333344 10.464 1.03572 11.7142 2.28596C12.9644 3.53621 13.6668 5.2319 13.6668 7.00001C13.6668 10.6819 10.6821 13.6667 7.00016 13.6667ZM10.1402 4.52668C10.2664 4.65185 10.3374 4.82225 10.3374 5.00001C10.3374 5.17777 10.2664 5.34817 10.1402 5.47334L6.14016 9.47334C6.01499 9.59955 5.84459 9.67054 5.66683 9.67054C5.48907 9.67054 5.31867 9.59955 5.1935 9.47334L3.86016 8.14001C3.69106 7.9709 3.62501 7.72443 3.68691 7.49342C3.74881 7.26242 3.92924 7.08199 4.16024 7.02009C4.39125 6.95819 4.63772 7.02424 4.80683 7.19334L5.66683 8.06001L9.1935 4.52668C9.31867 4.40047 9.48907 4.32948 9.66683 4.32948C9.84459 4.32948 10.015 4.40047 10.1402 4.52668ZM7.00016 1.66668C9.94568 1.66668 12.3335 4.05449 12.3335 7.00001C12.3335 8.4145 11.7716 9.77105 10.7714 10.7712C9.7712 11.7714 8.41465 12.3333 7.00016 12.3333C4.05464 12.3333 1.66683 9.94553 1.66683 7.00001C1.66683 4.05449 4.05464 1.66668 7.00016 1.66668Z"
      fill="#34A853"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style></style>
