import { RouteConfig, Route, NavigationGuardNext } from "vue-router";
import { store } from "@/state/store";
import * as types from "@/state/types";
export const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    meta: {
      beforeResolve(
        routeTo: Route,
        routeFrom: Route,
        next: NavigationGuardNext
      ) {
        if (store.getters[types.GET_USER_TOKEN]) {
          next();
        } else {
          store.dispatch(types.GET_TOKEN);
        }
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "Home-page" */ "@/router/pages/Home/Home.vue"
      ),
  },
  {
    path: "/create",
    name: "Create-Warehouse",
    component: () =>
      import(
        /* webpackChunkName: "Home-page" */ "@/router/pages/CreateWarehouse/create.vue"
      ),
  },
  {
    path: "/edit/:warehouseId",
    name: "Edit-Warehouse",
    component: () => import("@/router/pages/EditWarehouse/edit.vue"),
  },
  {
    path: "/show/:warehouseId",
    name: "Show-Warehouse",
    component: () => import("@/router/pages/ShowWarehouse/show.vue"),
  },
];
